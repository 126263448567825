import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from './../../environments/environment';
import { User } from './../common/types/user';

@Injectable()
export class UserService {
	constructor(private readonly rest: RestService) { }

	getRefUser(): Promise<any> {
		return this.rest.get(`${environment.apiUrl}/api/user`);
	}

	getUser(id: number): Promise<any> {
		return this.rest.get(`${environment.apiUrl}/api/user/${id}`);
	}

	getOrganizationUsers(filterBy: string = ""): Promise<any> {
		const filterByLowerCase = filterBy.toLocaleLowerCase();

		const getFilteredData = users => users.data.filter((user: User) =>
				user.firstName.toLocaleLowerCase().includes(filterByLowerCase)
			|| user.lastName.toLocaleLowerCase().includes(filterByLowerCase)
			|| user.email.toLocaleLowerCase().includes(filterByLowerCase)
			|| (user.role && user.role.toLocaleLowerCase().includes(filterByLowerCase))
		);

		const getAllData = users => users.data;

		return this.rest
			.get(`${environment.apiUrl}/api/user/organization`)
			.then(filterBy
					? getFilteredData
					: getAllData
			)
			// TODO: users are sorted by first name throughout the application; revisit this quite questionable UX decision
			.then((users: User[]) => users.sort((a, b) => a.firstName.localeCompare(b.firstName)));
	}

	createUser(payload): Promise<any> {
		return this.rest.post(`${environment.apiUrl}/api/user`, payload);
	}

	saveRefUser(payload): Promise<any> {
		return this.rest.put(`${environment.apiUrl}/api/user`, payload);
	}

	saveUser(id: number, payload): Promise<any> {
		return this.rest.put(`${environment.apiUrl}/api/user/${id}`, payload);
	}

	deleteUser(id: number): Promise<any> {
		return this.rest.delete(`${environment.apiUrl}/api/user/${id}`);
	}
}
