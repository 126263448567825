import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from './../../environments/environment';
import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  public readonly refOrganizationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private restService?: RestService;

  constructor(injector: Injector) {
    setTimeout(() => this.restService = injector.get(RestService), 0); // break circular dependency screaming at me (and you) from Angular
  }

  getOrganizations(): Promise<any> {
    return this.restService?.get(`${environment.apiUrl}/api/organization`);
  }

  getOrganizationsAdmin(): Promise<any> {
    return this.restService?.get(`${environment.apiUrl}/api/organization/full-list`);
  }

  getRefOrganization(): Promise<any> {
    return this.restService?.get(`${environment.apiUrl}/api/organization/ref`);
  }

  setOrganizationIsActive(organizationId: number, value: boolean): Promise<any> {
    return this.restService?.post(`${environment.apiUrl}/api/organization/${organizationId}/set-active`, value.toString());
  }

  addUser(payload): Promise<any> {
    return this.restService?.post(`${environment.apiUrl}/api/organization/user/add`, payload);
  }
}
