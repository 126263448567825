import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';

import { UiKitButtonDirective } from './button/button';
import {
  UiKitCardDirective,
  UiKitCardHeaderDirective,
  UiKitCardTabDirective,
  UiKitCardSubheaderDirective,
} from './card/card';
import { UiKitAnchorDirective } from './anchor/anchor';
import { UiKitFileUploaderComponent } from './file-uploader/file-uploader.component';
import { UiKitHeadingComponent } from './heading/heading';
import { UiKitSubheadingComponent } from './subheading/subheading';
import { UiKitInputComponent } from './input/input';
import { UiKitLabelComponent } from './label/label';
import { UiKitKeyValueComponent } from './key-value/key-value';

@NgModule({
  declarations: [
    UiKitButtonDirective,
    UiKitCardDirective,
    UiKitCardHeaderDirective,
    UiKitCardTabDirective,
    UiKitCardSubheaderDirective,
    UiKitAnchorDirective,
    UiKitFileUploaderComponent,
    UiKitHeadingComponent,
    UiKitSubheadingComponent,
    UiKitInputComponent,
    UiKitLabelComponent,
    UiKitKeyValueComponent,
  ],
  imports: [CommonModule, FormsModule, MatIconModule],
  exports: [
    UiKitButtonDirective,
    UiKitCardDirective,
    UiKitCardHeaderDirective,
    UiKitCardTabDirective,
    UiKitCardSubheaderDirective,
    UiKitAnchorDirective,
    UiKitFileUploaderComponent,
    UiKitHeadingComponent,
    UiKitSubheadingComponent,
    UiKitInputComponent,
    UiKitLabelComponent,
    UiKitKeyValueComponent,
  ],
})
export class UiKitModule {}
